import React from "react";

import { QUERY } from "~/utils/media";
import { removeWidows } from "~/utils/string";
import { Carousel } from "~/components/carousel";
import { HeadlineBlock } from "~/components/headline-block";
import { HeroSubheadline } from "~/components/typography";

import {
  TestimonialWrapper,
  Testimonial,
  TestimonialContent,
  TestimonialAuthor,
  TestimonialAvatar,
} from "./testimonials.style";

export const Testimonials = () => {
  const testimonials = [{
    color: "accentTwo",
    testimonial: "The Good Snail was so much fun and easy to send a card to my loved ones during these times. I’m pregnant and do not like going into stores to shop, let alone buy a card. I highly recommend it. There so many options that are adorable and creative. It’s addicting!",
    author: "Gili Z.",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "These edgy cards are just perfect on so many levels.",
    author: "Ann Marie",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "Anytime I’m looking for a card for my partner, I know I can rely on The Good Snail to get something out of the box. He loves sarcasm, sincerity, and sweetness, which is what I get with these cards. They’re the perfect go-to when I’m feeling sassy and sentimental.",
    author: "Ali C.",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "I love these cards, and I can’t wait to give my grandma the stripper card!",
    author: "Zach J.",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "The Good Snail's curated collection — from Wu-Tang to work wife — makes it easy to pick the perfect card. The best part: hearing that the recipient loved that “perfect card” I picked out for them!",
    author: "Sheba",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "They Mos Def are not your average cards!",
    author: "Lexi",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "The Good Snail kills it with a variety of cards! I can always find the perfect one for my special someone.",
    author: "Jamal",
    imagePath: "",
  }, {
    color: "accentTwo",
    testimonial: "The cards have made me appreciate greeting cards more than I have ever before. I didn’t know how hilarious they could be and they have helped make me send more mail.",
    author: "Dan",
    imagePath: "",
  }];

  const items = testimonials.map(({
    color,
    testimonial,
    author,
    imagePath,
  }) => (
    <Testimonial key={author}>
      <TestimonialContent {...{ color }}>
        {testimonial.length < 100
          ? <HeroSubheadline>{removeWidows(testimonial)}</HeroSubheadline>
          : removeWidows(testimonial)}
        <TestimonialAuthor>
          {author}
        </TestimonialAuthor>
      </TestimonialContent>
      <TestimonialAvatar $imagePath={imagePath} />
    </Testimonial>
  ));

  const options = {
    initial: 1,
    loop: true,
    mode: "free-snap",
    slidesPerView: 2.5,
    spacing: 0,
    breakpoints: {
      [QUERY.XLARGE]: {
        slidesPerView: 3.75,
      },
      [QUERY.MEDIUM]: {
        slidesPerView: 2,
      },
      [QUERY.SMALL]: {
        slidesPerView: 1.2,
        centered: false,
      },
    },
  }

  return (
    <TestimonialWrapper>
      <HeadlineBlock
        value={<>Some people<br />are saying</>}
        color="accentTwo"
      />
      <Carousel {...{ items, options }} />
    </TestimonialWrapper>
  )
};
