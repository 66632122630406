import styled, { css } from "styled-components";

import { IS_SMALL } from "~/utils/media";

export const Quote = styled.div`
  ${({ theme }) => theme.typography.heroHeadline};
  position: relative;
  z-index: 2;
  max-width: 75%;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.white};
  font-size: calc((56 / var(--base-font-size)) * 1rem);

  ${IS_SMALL} {
    ${({ theme }) => theme.typography.heroSubheadline};
  }
`;

export const BlockquoteWrapper = styled.blockquote`
  overflow: hidden;
  position: relative;
  display: block;
  padding: 4rem 0;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 25%;
    left: -6rem;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.accentTwo};
    border-radius: 8px;
    transform: skewX(-16deg);
  }

  ${Quote} {
    margin-right: auto;
    margin-left: 0;
  }

  ${({ right }) => right && css`
    text-align: right;

    &:before {
      right: -6rem;
      left: 25%;
      transform: skewX(16deg);
    }

    ${Quote} {
      margin-right: 0;
      margin-left: auto;
    }
  `};

  ${IS_SMALL} {
    padding: 2rem 0;
  }
`;
