import React from "react";
import PropTypes from "prop-types";

import { removeWidows } from "~/utils/string";
import { Grid } from "~/components/grid";

import {
  BlockquoteWrapper,
  Quote
} from "./blockquote.style";

export const Blockquote = ({ value, right }) => (
  <BlockquoteWrapper {...{ right }}>
    <Grid>
      {value && <Quote>{removeWidows(value)}</Quote>}
    </Grid>
  </BlockquoteWrapper>
);
Blockquote.propTypes = {
  value: PropTypes.string,
  right: PropTypes.bool,
};
