import React from "react";

import { Main } from "~/layouts";
import { Seo } from "~/components/seo";
import { HeroStatic } from "~/components/hero";
import { AsSeenOn } from "~/components/as-seen-on";
import { Blockquote } from "~/components/blockquote";
import { HowItWorks } from "~/components/how-it-works";
import { ProductGallery } from "~/components/products/product-gallery";
// import { DesignerGallery } from "~/components/designers/designer-gallery";
// import { HeadlineBlock } from "~/components/headline-block";
import { Testimonials } from "~/components/testimonials";
// import { WritersBlock } from "~/components/writers-block";
import { Footer } from "~/components/footer";
import { Story } from "~/components/story";

import {
  useLatest,
  useBestSellers,
  useCategories,
} from "~/data";

const Index = () => {
  const { products: latest } = useLatest();
  const { products: bestSellers } = useBestSellers();
  // const { products: byCategory } = useCategories(["valentines-day"]);

  return (
    <Main>
      <Seo />
      <HeroStatic />
      <AsSeenOn />
      <HowItWorks />
      <Blockquote value="Cards don't cure but they sure AF help." />
      {/*<ProductGallery
        headline="Love Is in the Air"
        products={byCategory.splice(0,6)}
        cta={{
          label: "Shop V-Day Cards",
          path: "/collections/valentines-day",
        }}
      />*/}
      <ProductGallery
        headline="Just In!"
        products={latest}
        cta={{
          label: "Shop Latest",
          path: "/latest",
        }}
      />
      <ProductGallery
        headline={<>Best<br />Sellers</>}
        products={bestSellers}
        cta={{
          label: "Shop Best Sellers",
          path: "/best-sellers",
        }}
      />
      <Blockquote
        value="Cards rule everything around me."
        right
      />
      {/*<HeadlineBlock
        value={<>Featured<br />Designers</>}
        color="accentTwo"
      />
      <DesignerGallery name="Love & Lemons Paperie" slug="designer-love-lemons-paperie" />
      <DesignerGallery name="Hu Is Hungry" slug="designer-hu-is-hungry" />
      <DesignerGallery name="Brittany Page" slug="designer-brittany-page" />*/}
      {/*<WritersBlock />*/}
      <Testimonials />
      <Story />
      <Footer />
    </Main>
  );
}

export default Index
