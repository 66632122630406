import styled from "styled-components";

import { IS_SMALL, IS_MEDIUM } from "~/utils/media";

export const HowItWorksWrapper = styled.section`
  text-align: center;
  margin-bottom: 8rem;
`;

export const HowItWorksItems = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  ${IS_MEDIUM} {
    flex-wrap: wrap;
  }

  ${IS_SMALL} {
    flex-direction: column;
  }
`;

export const HowItWorksItem = styled.div`
  width: 33%;
  padding: 0 2rem;

  img {
    display: block;
    max-width: 240px;
    margin: auto;
  }

  ${IS_MEDIUM} {
    width: 50%;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  ${IS_SMALL} {
    width: 100%;
    margin-bottom: 2rem;
    padding: 0 1rem;

    &:first-child {
      padding-left: 1rem;
    }

    &:last-child {
      padding-right: 1rem;
    }
  }
`;
