import React from "react";
import { navigate } from "gatsby";
import Button from '@mui/material/Button';

import { Grid } from "~/components/grid";
import { HeroHeadline, Subheadline, Body } from "~/components/typography";

import {
  HowItWorksWrapper,
  HowItWorksItems,
  HowItWorksItem,
} from "./how-it-works.style";

export const HowItWorks = () => (
  <HowItWorksWrapper>
    <Grid>
      <HeroHeadline color="accentTwo">How it works</HeroHeadline>
    </Grid>
    <Grid>
      <HowItWorksItems>
        <HowItWorksItem>
          <img
            src="https://res.cloudinary.com/thegoodsnail/image/upload/v1639333524/elements/pick-it_fomygc.png"
            alt="manicured hands selecting a card"
          />
          <Subheadline color="accentTwo">You Pick</Subheadline>
          <Body>Pick from hundreds of <strong>REAL</strong> cards by <strong>REAL</strong> independent designers</Body>
        </HowItWorksItem>
        <HowItWorksItem>
          <img
            src="https://res.cloudinary.com/thegoodsnail/image/upload/v1639333524/elements/personalize_ufatkt.png"
            alt="manicured hands writing a message on a card"
          />
          <Subheadline color="accentTwo">You Personalize</Subheadline>
          <Body>Craft your own personalized message</Body>
        </HowItWorksItem>
        <HowItWorksItem>
          <img
            src="https://res.cloudinary.com/thegoodsnail/image/upload/v1639334368/elements/deliver_aorhq6.png"
            alt="envelope being delivered"
          />
          <Subheadline color="accentTwo">We Deliver</Subheadline>
          <Body>We'll take care of delivering it for you</Body>
        </HowItWorksItem>
      </HowItWorksItems>
    </Grid>
    <Grid>
      <Button
        variant="contained"
        sx={{ minWidth: 200 }}
        onClick={() => navigate("/search")}
      >
        Get Started
      </Button>
    </Grid>
  </HowItWorksWrapper>
);
