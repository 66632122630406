import React from "react";
import Button from '@mui/material/Button';
import { navigate } from "gatsby";

import { useIntersectionObserver } from "~/hooks";
import { getImageSrc } from "~/utils/cloudinary";
import { Grid } from "~/components/grid";
import {
  HeroSubheadline,
  BodyHeadline,
} from "~/components/typography";

import {
  Wrapper,
  Content,
} from "./story.style";

export const Story = () => {
  const [intersectionRef, isInView] = useIntersectionObserver();
  const imagePath = getImageSrc("julie-web-headshot-crop_elikfd");

  return (
    <Wrapper
      ref={intersectionRef}
      $imagePath={isInView && imagePath}
    >
      <Grid>
        <Content>
          <HeroSubheadline>Our Story</HeroSubheadline>
          <BodyHeadline>We’re making it easy to help you deliver meaningful messages while supporting independent artists and sharing our love of connection through cards.</BodyHeadline>
          <Button
            color="light"
            variant="contained"
            size="small"
            sx={{ minWidth: 200 }}
            onClick={() => navigate("/story")}
          >
            Learn More
          </Button>
        </Content>
      </Grid>
    </Wrapper>
  );
}
