import styled from "styled-components";

import { IS_SMALL } from "~/utils/media";
import { BodyHeadline } from "~/components/typography";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 14rem 0 3rem;
  color: ${({ theme }) => theme.colors.white};
  background-image: url(${({ $imagePath }) => $imagePath});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(54, 23, 94, 0.2);
    mix-blend-mode: multiply;
  }

  ${IS_SMALL} {
    flex-direction: column;
  }
`;

export const FounderImage = styled.figure`
  display: block;
  width: 50%;
  height: 100%;
  margin-right: 2rem;
  padding-top: 100%;
  background-image: url(${({ $imagePath }) => $imagePath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${IS_SMALL} {
    width: 90vw;
    height: 80vmin;
    margin-right: 0;
    margin-bottom: 2rem;
    background-position: center top;
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  width: 60%;
  max-width: 880px;
  margin: auto;
  text-align: center;
  text-shadow: 0 8px 32px rgba(0,0,0, 0.65);

  ${BodyHeadline} {
    margin-top: 1rem;
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  ${IS_SMALL} {
    width: 90vw;
    max-width: 480px;
  }
`;
