import styled from "styled-components";

import { IS_SMALL } from "~/utils/media";

export const TestimonialWrapper = styled.div`
  margin: 6rem 0 8rem;

  ${IS_SMALL} {
    margin-top: 3rem;
  }
`;

export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 2rem 4rem;
  text-align: center;
  border: 1px solid ${({ theme }) =>
    theme.colors.accentOne};
  border-top: none;
  border-bottom: none;

  ${IS_SMALL} {
    padding: 1rem 2rem;
  }
`;

export const TestimonialContent = styled.div`
  ${({ theme }) => theme.typography.bodyHeadline};
  position: relative;
  line-height: 2;
  padding-top: 2rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 12%;
    min-width: 72px;
    height: 8px;
    background-color: ${({ theme, color }) =>
      theme.colors[color || "accentTwo"]};
    transform: translateX(-50%);
    border-radius: 48px;
  }
`;

export const TestimonialAuthor = styled.div`
  ${({ theme }) => theme.typography.small};
  margin-top: 0.5rem;
  margin-left: 1rem;
  white-space: nowrap;

  &:before {
    content: '—';
  }
`;

export const TestimonialAvatar = styled.div``;
